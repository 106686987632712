export const doctorEnrollmentTicketBody = (request) => {
  let {
    firstName,
    lastName,
    emailAddress,
    mobileNumber,
    prcLicenseNumber,
    specialization,
    otherSpecialization,
    primaryClinic,
    referral,
  } = request

  return `Personal Details
  First Name: ${firstName}
  Last Name: ${lastName}
  Email: ${emailAddress}
  Mobile Number: ${mobileNumber}
  PRC License Number: ${prcLicenseNumber || "N/A"}
  Specialization: ${
    specialization?.value === "Other"
      ? otherSpecialization
      : specialization?.value
  }
  ---
  Primary Office
  Hospital/Clinic: ${
    primaryClinic?.name?.value !== "Other"
      ? primaryClinic?.name?.value
      : primaryClinic?.otherHospital
  }
  Clinic Hours: ${primaryClinic?.clinicHours}
  Clinic Contact Number: ${primaryClinic?.clinicContactNumber}
  Street Address: ${primaryClinic?.streetAddress}
  City: ${primaryClinic?.city?.value}
  Province: ${primaryClinic?.province?.value}
  ---
  Referral
  Full Name: ${referral?.fullName || "N/A"}
  `
}
