import React, { useContext, useEffect, useState, Fragment } from "react"
import { navigate } from "gatsby"

import Section from "elements/Section"
import FormCheckbox from "../Elements/Form/FormCheckbox"
import ActionButtons from "elements/ActionButtons"
import EditDetailsButton from "../Summary/EditDetailsButton"
import ConsentAndAuthorization from "../Summary/ConsentAndAuthorization"
import ClinicsInformationSummary from "./DoctorEnrollmentSummary/ClinicsInformationSummary"
import PersonalInformationSummary from "./DoctorEnrollmentSummary/PersonalInformationSummary"

import {
  sendEnrollmentForm,
  shouldDoctorButtonBeDisabled,
} from "./services/enrollment"
import { AppContext } from "../../context/AppContext"
import termsAndConditionsData from "./utils/doctorConsentAndAuthorization.json"
import doctorEnrollmentTemplate from "./utils/templates/doctorEnrollmentEmailTemplate"
import { doctorTextMessageTemplate } from "./utils/templates/enrollmentTextMessageTemplate"
import { doctorEnrollmentTicketBody } from "./utils/templates/doctorEnrollmentZendeskTemplate"

const isLicensedPhysicianBlurb =
  "I acknowledge that I am a licensed physician in the Philippines."

const medicationInAccordanceBlurb =
  "I confirm that I am prescribing medication in accordance with the product indications approved by the Philippine Food and Drug Administration (PFDA)."

const DoctorEnrollmentSummary = () => {
  const [isLoading, setLoading] = useState(false)
  const { state, dispatch } = useContext(AppContext)
  const { doctorEnrollment } = state

  useEffect(() => {
    dispatch({ type: "GET_CONTEXT_FROM_SESSION" })
  }, [])

  const handleSuccessSubmit = async () => {
    setLoading(false)
    await dispatch({ type: "REMOVE_CONTEXT_FROM_SESSION" })
    await dispatch({ type: "RESET_DETAILS" })
    navigate("/complete", {
      state: {
        type: "doctor",
      },
    })
  }

  const handleErrorSubmit = () => {
    setLoading(false)
    navigate("/complete", {
      state: {
        type: "doctor",
      },
    })
  }

  const handleSubmit = async () => {
    setLoading(true)
    await sendEnrollmentForm({
      enrollmentData: doctorEnrollment,
      emailTemplate: doctorEnrollmentTemplate,
      ticketTemplate: doctorEnrollmentTicketBody,
      type: "doctor",
      callback: handleSuccessSubmit,
      errorCallback: handleErrorSubmit,
      textMessageTemplate: doctorTextMessageTemplate,
    })
  }

  return (
    <Fragment>
      <Section
        title="Personal Information"
        addOns={{
          right: (
            <EditDetailsButton route="/enroll-doctor#personal-information" />
          ),
        }}
      >
        <PersonalInformationSummary doctorEnrollment={doctorEnrollment} />
      </Section>

      <Section
        title="Clinic Information"
        addOns={{
          right: (
            <EditDetailsButton route="/enroll-doctor#clinic-information" />
          ),
        }}
      >
        <ClinicsInformationSummary doctorEnrollment={doctorEnrollment} />
      </Section>

      <Section
        title="Referral"
        addOns={{
          right: (
            <EditDetailsButton route="/enroll-doctor#referral-information" />
          ),
        }}
      >
        <p>
          Full Name:{" "}
          <span className="has-text-weight-bold">
            {doctorEnrollment?.referral?.fullName}
          </span>
        </p>
      </Section>

      <ConsentAndAuthorization
        handleOnSubmit={handleSubmit}
        termsAndConditionsData={termsAndConditionsData}
        flow="doctorEnrollment"
        additionalBottomCheckboxes={[
          <FormCheckbox
            name="isLicensedPhysician"
            values={doctorEnrollment.isLicensedPhysician}
            options={[isLicensedPhysicianBlurb]}
            isRequired
            onChange={(event) =>
              dispatch({
                type: `SAVE_IS_LICENSED_PHYSICIAN`,
                payload: event.target.checked ? [isLicensedPhysicianBlurb] : [],
              })
            }
          />,
          <FormCheckbox
            name="medicationInAccordance"
            values={doctorEnrollment.medicationInAccordance}
            options={[medicationInAccordanceBlurb]}
            isRequired
            onChange={(event) =>
              dispatch({
                type: `SAVE_MEDICATION_IN_ACCORDANCE`,
                payload: event.target.checked
                  ? [medicationInAccordanceBlurb]
                  : [],
              })
            }
          />,
        ]}
      >
        <ActionButtons
          hasCaptcha={true}
          back={{ label: "Back", link: "/enroll-doctor" }}
          submit={{
            loading: isLoading,
            label: "Submit",
            disabled: shouldDoctorButtonBeDisabled({
              formData: doctorEnrollment,
            }),
          }}
        />
      </ConsentAndAuthorization>
    </Fragment>
  )
}

export default DoctorEnrollmentSummary
